import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.openModal,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.openModal) = $event)),
    "show-close": false,
    "close-on-click-modal": false,
    width: "450px",
    title: 'Are you sure you want delete?',
    "custom-class": "confirmation-popups"
  }, {
    default: _withCtx(() => [
      _createTextVNode(" This will delete all manual orders? ")
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_button, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cancelModal', _ctx.openModalValue)))
        }, {
          default: _withCtx(() => [
            _createTextVNode("Cancel")
          ]),
          _: 1
        }),
        (!_ctx.openModalLoading)
          ? (_openBlock(), _createBlock(_component_el_button, {
              key: 0,
              type: "primary",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('proceedModal', _ctx.openModalValue)))
            }, {
              default: _withCtx(() => [
                _createTextVNode("Proceed")
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_el_button, {
              key: 1,
              type: "primary",
              loading: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode("Loading")
              ]),
              _: 1
            }))
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}