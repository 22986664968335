
import { useStore } from "vuex";
import { defineComponent, onMounted, computed, ref, onBeforeMount } from "vue";
import { themeName, demo } from "@/core/helpers/documentation";
import QuotesService from "@/core/services/car/QuotesService";

// import LeadDriverDetailsListView from "@/components/partials/leaddriverdetails/Lead-DriverDetails-List.vue";
import LeadDriverDetailsView from "@/components/partials/leaddriverdetails/Lead-DriverDetiails-View.vue";
interface QuotesBodyParam {
  customer_id: number;
  lead_id: number;
}
export default defineComponent({
  name: "view-lead",
  components: {
    // QuotesAction,
    // LeadDriverDetailsListView,
    LeadDriverDetailsView,
  },
  props: {
    leadDetails: Object,
    leadDriverDetails: Array,
    customer: Object,
    leadList: Array,
    editObj: Object,
    isLoaded: Boolean,
  },
  data() {
    return {
      //  emirates:['Abu Dhabi','Ajman','Dubai','Fujairah','Ras Al Khaimah','Sharjah','Umm Al Quwain'],
    };
  },

  setup(props, context) {
    const store = useStore();
    let isActive = false;
    let getEditObj = ref<any>();
    // const sendQuote = (quote_id) => {
    //   QuotesService.sendQuote({"quote_id":quote_id});
    // }
    const driverDetailsId = ref(props.leadDriverDetails);
    const leadDetails = ref(props.leadDetails);
    let key = ref<any>();

    let quotesBodyParam = {} as QuotesBodyParam;
    quotesBodyParam = {
      customer_id: -1,
      lead_id: -1,
    };

    const leadDe = computed(() => {
      return store.state.QuotesModule.leadDetails[0];
    });

    const getDriverDetailsList = (
      customer_id,
      lead_id = -1,
      driver_details_id
    ) => {
      Promise.all([
        QuotesService.fetchQuote({
          driver_details_id: driver_details_id,
        }),
      ]).then((data) => {
        getEditObj = computed(() => {
          return store.getters.getEditQuote;
        });
        key.value = driver_details_id;
      });
      quotesBodyParam.customer_id = customer_id;
      quotesBodyParam.lead_id = lead_id;
      QuotesService.getDriverDetailsList(quotesBodyParam);
    };
    const showQuotesDom = computed(() => {
      return store.getters.getQuotesDomStatus;
    });

    //  setInterval(function () {
    //  });
    getEditObj = computed(() => {
      return store.getters.getEditQuote;
    });

    const closingButton = ref()
    const closingTime = (e) => {
      if(e.which === 27) {
        closingButton.value.click();
      }
    }

    onBeforeMount(() => {
      window.addEventListener('keyup', closingTime);
    })

    let resetEditObj = () => {
      QuotesService.resetEditObject();
      context.emit("resetLoaded");
    };

    return {
      closingButton,
      leadDe,
      isActive,
      getDriverDetailsList,
      showQuotesDom,
      getEditObj,
      key,
      resetEditObj,
    };
  },
});
