
import { defineComponent, computed, getCurrentInstance } from 'vue'
import { useStore } from "vuex";

export default defineComponent({  
  setup() {
    const store = useStore();

    const checkTrimLevel = computed(() => {
      return store.getters.getCheckTrimLevel;
    });

    return {
      checkTrimLevel
    }
  },
})
